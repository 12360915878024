<script lang="ts">
export default {
  name: 'MessengerIcon'
}
</script>

<template>
  <img
    src="@/assets/img/svg/messenger-icon.svg"
    alt="messenger"
    width="31"
    height="31"
  />
</template>
