<script lang="ts">
export default {
  name: 'FacebookIcon'
}
</script>

<template>
  <img
    src="@/assets/img/svg/facebook-icon.svg"
    alt="facebook"
    width="31"
    height="31"
  />
</template>
