<script lang="ts">
export default {
  name: 'SnapchatIcon'
}
</script>

<template>
  <img
    src="@/assets/img/svg/snapchat-icon.svg"
    alt="snapchat"
    width="31"
    height="31"
  />
</template>
