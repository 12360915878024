<script lang="ts">
export default {
  name: 'InstagramIcon'
}
</script>

<template>
  <img
    src="@/assets/img/svg/instagram-icon.svg"
    alt="instagram"
    width="31"
    height="31"
  />
</template>
