<script lang="ts">
export default {
  name: 'PrimeVideoLogo'
}
</script>

<template>
  <img
    src="@/assets/img/svg/prime-video-logo.svg"
    alt="Prime Video"
  />
</template>
