<script lang="ts">
export default {
  name: 'TwitterIcon'
}
</script>

<template>
  <img
    src="@/assets/img/svg/twitter-icon.svg"
    alt="twitter"
    width="30"
    height="30"
  />
</template>
